
.social-signin {
  max-width: 330px; margin: auto;
  a { border: none; margin-bottom: 0.25rem; }
  a.facebook { background-color: #1877f2; }
  a.google { background-color: #4285f4; }
  a.instagram { background-color: #c32aa3; }
  a.apple { background-color: #a6b1b7; }
  i { margin-right: 0.15rem; }
}

.users-links {
  margin-top: 0.5rem; text-align: center;
  a { display: inline-block; margin-right: 0.5rem; }
}

.narrow-form {
  .checkbox { font-weight: 400; }
  .form-floating:focus-within { z-index: 2; }
  .form-floating-fields {
    input.form-control { border-radius: 0;}
    :first-child input.form-control { margin-bottom: -1px; border-top-right-radius: 8px; border-top-left-radius: 8px; }
    :last-child input.form-control { margin-bottom: 10px; border-bottom-right-radius: 8px; border-bottom-left-radius: 8px; }
  }
  .actions { margin-top: 0.5rem; }
}

.col-all { text-align: center !important; }

.signin {
  .or { margin: 1em 0; }
}
@include media-breakpoint-up(md) {
  .signin {
    .row { display: table; min-height: 100%; width: 100%; }
    .col-all { display: table-cell; min-height: 100%; vertical-align: middle; }
    .col-lg-5 { width: 45%; }
    .col-lg-2 { width: 10%; }
    .social-signin { max-width: 475px; margin: inherit; }
  }
}