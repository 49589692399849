// Ensure no whitespace below footer.
body.account {
  display: flex; min-height: 100vh; flex-direction: column;
  main { flex: 1; }

  header {
    background-color: #f1f2fc;

    .navbar-brand {
      font-size: 1rem; padding: 0;
      img { height: 2rem; margin-top: -0.75rem; }
      .words { display: inline-block; color: #999; font-weight: 800; font-size: 1.5rem; color: darkblue; }
      .guc { font-size: 0.8rem; line-height: 1rem; margin-top: 0.5rem; }
      .sacrament { font-weight: 800; line-height: 1rem; }
    }

    img.current_user { max-height: 35px; border-radius: 25px; margin: -5px 0; background-color: white;}
  }
  &.anonymous {
    header, header .container { justify-content: center; }
  }

  footer {
    font-size: 0.9rem;
    background-color: #f1f2fc;
    padding: 1rem 0;
    .bi-heart-fill { color: $red-600; }
    a { text-decoration: none; color: darkblue; }
    .copyright { font-size: 0.75rem; }
    img.logo { float: left; margin-right: 0.25rem; }
    @include media-breakpoint-up(md) {
      padding: 2rem 0;
    }
  }

  main {
    &.main-container { margin-top: 1rem; margin-bottom: 1rem; }
    @include media-breakpoint-up(md) {
      &.main-container { margin-top: 3rem; margin-bottom: 3rem; }
    }
  }

  &.account_home {
    .services {
      a { text-decoration: none; }
      .card {
        background-color: #eee;
        img { float: left; width: 70px; }
        h1 { font-weight: 800; font-size: 1.25rem; }
        .text { margin-left: 80px;}
      }
      .card:hover { background-color: #ddd; }
      .card.store { color: #17776f; }
      .card.fashioning {
        color: darkblue;
        img { padding: 0 5px; }
      }
      .card.creation { color: green; }
      .card.guc { color: #ff8400; }
      .card.accompaniment { color: brown; }
      .card.gospellink { color: red; }
    }
  }
}
